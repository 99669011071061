.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.m-15 {
  margin: 15px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.MuiButton-outlined.danger {
  border-color: red;
  color: red;
}

.App-intro {
  font-size: large;
}

.MuiSelect-root.MuiSelect-select {
  padding-right: 50px !important;
  min-width: 80px !important;
  padding-left: 15px;
}

.MuiButton-root {
  min-width: 40px;
  margin-bottom: 10px;
}

#branding-logo {
  max-height: 40px !important;
}

.border-danger {
  border: 1px solid red !important;
}

.icon-danger {
  color: red !important;
}

.icon-primary {
  color: #283593 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ql-container {
  height: 200px !important;
}

.span.MuiChip-label {
  max-width: 12px;
}

.MuiTableCell-head {
  /* line-height: 1em !important; */
}

.large-table-list {
  max-width: calc(100vw - 19vw);
}

.large-table-list-nav-open {
  max-width: calc(100vw - 5vw);
}

.d-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;
  padding: 0px 15px;
  margin-top: 20px;
}

.d-grid video,
.d-grid img {
  border-radius: 0px;
  width: 100%;
  height: auto;
}

.print-table td {
  padding: 8px 10px;
  border: 1px solid #e0e0e0;
  color: #333;
}

.print-table td h4 {
  font-size: 18px;
  font-weight: bold !important;
}

.print-table td:first-child:not(.brand-logo) {
  width: 150px;
  font-weight: 600;
}

.last-cell {
  max-width: 150px;
  text-align: center !important;
}

.print-view {
  max-width: 900px;
  margin: auto;
  margin: 20px auto;
}

@page {
  size: A4 landscape;
}

@media print {
  .hide-print-btn {
    display: none !important;
  }

  .print-view {
    height: max-content;
    width: 100%;
    max-width: 100%;
    margin: 0px !important;
  }

  .print-view button,
  .print-view .btn {
    display: none !important;
  }
}

.mt-10 {
  margin-top: 10px;
}

.dialogWidth3 .MuiDialog-paperWidthSm {
  max-width: 500px !important;
  width: 500px !important;
  height: 400px !important;
}

.dialogWidth4 .MuiDialog-paperWidthSm {
  max-width: 509px !important;
  width: 600px !important;
  height: 249px !important;
}

.dialogWidth3 .MuiDialog-paperWidthSm h2 span {
  padding: 0px;
}

.dialogWidth3 .MuiDialog-paperWidthSm video {
  object-fit: cover;
  border-radius: 0px;
  height: calc(100% - 8px);
}

.captured-img img {
  width: 100%;
}

.img-form form {
  flex: 0 0 calc(100% / 12 * 7);
  max-width: 100%;
  flex-basis: 100%;
}

.img-form .img-wrapper {
  flex: 0 0 calc(100% / 12 * 5);
  max-width: calc(100% / 12 * 5);
}

.img-form .captured-img {
  padding: 16px;
  padding-top: 25px;
}

.print-view {
  max-height: calc(100vh - 89px);
  overflow: auto;
  overflow-x: hidden;
}

.save-bg {
  position: absolute;
  bottom: 0;
  height: 63px;
  width: 100%;
  background-color: whitesmoke;
}

/* .RaAutocompleteArrayInput-inputRootFilled-122 {
  width: 545px;
} */

.shift-div {
  width: 70%;
}

.chip-display {
  display: inline-block;
  padding: 0 15px;
  height: 30px;
  font-size: 14px;
  line-height: 30px;
  border-radius: 25px;
  background-color: #e6e6e6;
}

.team-request-table td:hover svg {
  display: inline-block !important;
}

.visitor-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #283593;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  margin-bottom: 18px;
}

.visitor-heading-sm {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  background-color: #283593;
  padding: 10px;
  color: #fff;
  font-size: 16px;
  margin-bottom: 18px;
}

.visitor-page form {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.visitor-heading h6 {
  margin-bottom: 0px;
  color: #fff;
}

.form-wrapper {
  max-width: 50%;
  margin: auto;
  margin-bottom: 8px;
}

.w-50 {
  width: 50%;
}

.d-flex {
  display: flex;
}

.upload-photo-box {
  padding: 10px;
  border: 1px dashed #283593;
  text-align: center;
  max-width: calc(48% - 9px);
  margin: 00;
  color: #283593;
  border-radius: 6px;
  position: relative;
  left: calc(-28% - 2px);
  margin-left: auto;
  margin-bottom: 30px;
  cursor: pointer;
}

.sub-heading {
  text-align: center;
  max-width: calc(48% - 9px);
  color: #283593;
  border-radius: 6px;
  position: relative;
  left: calc(-28% - 2px);
  margin-left: auto;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 30px;
}

.mt-18 {
  margin-top: 18px;
}

.sub-heading h6 {
  font-size: 16px;
  position: relative;
  padding: 2px 20px;
  background-color: #fff;
  display: inline-block;
}

.sub-heading hr {
  margin-top: -15px;
}

.employee-leave-report .MuiToolbar-root {
  padding-top: 0;
}

.leaveType-input {
  height: 50px;
}

.leave-type-switch .MuiTypography-root {
  width: 103px;
}

.employee-filter {
  padding-left: 10px;
}

.multi-dropdown-width .MuiInputBase-root {
  width: 500px;
}

.request-time-field {
  transition: "background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms";
  backgroundcolor: "rgba(0, 0, 0, 0.04)";
  bordertopleftradius: "4px";
  bordertoprightradius: "4px";
  padding: "10px";
  width: "257px";
  marginleft: "32px";
  position: "relative";
  top: "18px";
}

.role-switch .MuiSwitch-root {
  padding: 14px;

  width: 50px;
}

.role-switch .MuiSwitch-thumb {
  height: 15px;
  width: 15px;
}

.role-switch .MuiSwitch-switchBase {
  top: 3px;
  transition: "background 0.25s ease 0s";
}

.role-table-bg {
  background: #e6e6e6;
}

.RaFileInput-p {
  margin-bottom: 0px;
  color: red;
}

.menu-arrow {
  color: rgba(0, 0, 0, 0.54);
  margin-top: 5px;
}

/* .sub-menu .MuiTypography-root {
  color: #3f51b5;
} */
input[type="text"] {
  -webkit-autocomplete: off;
  -moz-autocomplete: off;
  autocomplete: off;
}

.material-table th {
  padding: 0px;
}

.MuiTableCell-root {
  display: table-cell;
  padding: 10px !important;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.menu-hr-w {
  width: 200px;
  color: #00000073 !important;
  border-top: 1px solid #00000073;
}

.sub-menu-header {
  font-size: 14px;
  color: #283593d6;
  /* font-weight: bold; */
  text-decoration: none;
  /* font-family: "docs-Calibri"; */
  font-style: normal;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
  padding: 4px 15px;
}

.sub-menu-bg {
  background-color: #f5f5f5;
}

.right-side-menubar {
  /* height: calc(100vh - 50px); */
  /* background-color: white; */
  /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
}

main > div:nth-child(2) {
  /* margin-right: 10px; */
  margin-left: 240px;
}
.content-left main > div:nth-child(2) {
  margin-left: 55px;
}

.main-content {
  padding: 0 15px;
}
.arrow-sub-menu {
  color: #0000008a;
  font-size: 21px;
  position: relative;
  right: 3px;
}

.image-upload {
  cursor: pointer !important;
  width: "100px" !important;
  height: "100px" !important;
  color: "#293799" !important;
}

.delete-button-visitor {
  /* left: 450px; */
  position: relative;
  z-index: 1;
}

.active-menu {
  border: 1px solid #0e0e0e1f !important;
  border-radius: 8px !important;
  background: #74787b0f !important;
}

.MuiGridListTileBar-titleWrapActionPosRight {
  max-width: 199px;
  margin-right: 0px !important;
}

.MuiGridListTileBar-titleWrap {
  color: #fff;
  overflow: hidden;
  flex-grow: 1;
  /* margin-left: 14px !important; */
}

.menu-width {
  /* width: 230px; */
  /* height: 90vh;
  overflow-x: hidden;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-right: none;
  background-color: transparent; */
  /*  */

  /* overflow: hidden !important;
  overflow-y: scroll !important;
  height: 93vh !important;
  position: fixed !important;
  background-color: white !important;
  width: 20% !important;
  z-index: 10 !important; */

  /* width: 247px; */
  height: 90vh;
  overflow-x: hidden;
  transition: width 247ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  /* border-right: none; */
  /* background-color: transparent; */
  /* position: fixed; */
  /* margin-left: auto; */
}

.right-side-menubar {
  overflow: hidden !important;
  overflow-y: scroll !important;
  height: 93vh !important;
  position: fixed !important;
  background-color: white !important;
  width: 20% !important;
  z-index: 10 !important;
}

/* High Charts */
/* 
.highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 100;
  padding: 0.5em;
}

.highcharts p {
  text-transform: none;
  font-size: 10px;
  font-weight: normal;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

#container h4 {
  text-transform: none;
  font-size: 10px;
  font-weight: normal;
}

.chartContainer h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.chartContainer p {
  font-size: 14px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #c7d6df !important;
} */

/* #container p {
  font-size: 10px;
  line-height: 10px;
  color: #e0e0e0;
} */

/* @media screen and (max-width: 600px) {
  #container h4 {
    font-size: 2.3vw;
    line-height: 3vw;
  }

  #container p {
    font-size: 2.3vw;
    line-height: 3vw;
  }
} */

.MuiAppBar-positionFixed {
  top: 0 !important;
  left: auto;
  right: 0;
  position: fixed;
  transform: translateY(0px) !important;
  visibility: visible !important;
}

/* .MuiDrawer-docked {
  overflow: hidden !important;
  overflow-y: scroll !important;
  height: 93vh !important;
  position: fixed !important;
  background-color: white !important;
  width: 20% !important;
  z-index: 10 !important;
} */

/* .RaLayout-content-4 {
  margin-left: 290px !important;
} */

/* .content-left {
  margin-left: 290px !important;
} */

.rstcustom__rowSubtitle {
  font-size: 84%;
  line-height: 0.7;
  width: 95%;
  height: -31px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
}

.hierarchy-flex {
  display: flex;
}

.fire-div {
  /* color: rgb(247, 244, 244); */
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 1px solid; */
  padding: 2px;
  margin: 2px;
  border-radius: 10px;
  border: 6px dashed #dc4c64;
  width: 500px;
  /* background-color: #dc4c64; */
}

.attention-fire-div {
  color: #dc3545 !important;
  font-size: 32px;
  /* align-items: center; */
  /* justify-content: center; */
  display: flex;
  font-weight: 600;
}

.building-fire-div {
  font-size: 20px;
  /* align-items: center; */
  /* justify-content: center; */
  display: flex;
  font-weight: 600;
}

.premise-fire-div {
  font-size: 20px;
  /* align-items: l; */
  /* justify-content: center; */
  display: flex;
  font-weight: 500;
  border-bottom: 1px solid #a4afb7;
  margin-bottom: 4px;
}

.fire-img {
  position: fixed;
  margin-top: 22px;
  /* margin-right: -29px; */
  margin-left: -145px;
  /* background-color: blue; */
}

/* .main-content {
  height: calc(100vh - 100px);
  overflow: hidden;
  overflow-y: scroll;
} */

.form-content {
  height: calc(100vh - 100px);
  /* overflow: hidden; */
  overflow-y: scroll;
  width: calc(100vw - 260px);
}
.form-content-org-chart {
  height: calc(100vh - 100px);
  /* overflow: hidden; */
  overflow-y: scroll;
  width: calc(100vw - 260px);
  display: inline-flex;
  justify-content: center;
}

/* .list-view2>div:nth-child(2) {
  overflow: hidden;
  height: CALC(100vh - 140px);
  overflow-y: auto;
} */

.role-module-title {
  font-size: 17px;
  font-weight: 600;
  margin: 10px;
  color: #3f51b5;
}

.role-module-subtitle {
  font-size: 15px;
  font-weight: 600;
  margin-left: 19px;
}

.role-module-div {
  border: 1px solid #c5c2c2;
  padding: 2px;
  margin-bottom: 3px;
  border-radius: 5px;
}

.team-list {
  height: calc(100vh - 333px);
}

.react-select-container {
  min-width: 170px;
  max-width: 190px;
}

.public-report {
  border: 1px solid #c5c2c2;
  margin: 2px;
}

.public-date-input {
  border: 1px solid #c5c2c2;
  border-radius: 5px;
  /* background-color: #fff; */
  padding: 3px 5px;
  /* box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1); */
  width: 190px;
  height: 40px;
}

.myClass {
  stroke: red;
}

/* @import "https://code.highcharts.com/css/highcharts.css"; */

/* .highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
} */

/* .highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
} */
/* .highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
} */
/* .highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
} */
/* .highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
} */
/* .highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
} */
/* .highcharts-data-table tr:hover {
  background: #f1f7ff;
} */

/* #container h4 {
  text-transform: none;
  font-size: 14px;
  font-weight: normal;
}
#container p {
  font-size: 13px;
  line-height: 16px;
} */

/* @media screen and (max-width: 600px) {
  #container h4 {
    font-size: 2.3vw;
    line-height: 3vw;
  }
  #container p {
    font-size: 2.3vw;
    line-height: 3vw;
  }
} */

.chartContainer h4 {
  font-size: 16px !important;
  font-weight: 500 !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.chartContainer p {
  font-size: 12px !important;
  line-height: normal;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #c7d6df !important;
}

.css-to7de5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  margin: 0;
  padding-top: var(--tree-line-height);
  position: relative;
  padding-top: 9px;
  top: -8px;
  color: #307578;
}

.MuiInputAdornment-root {
  display: none !important;
}

.mb-18 {
  margin-top: 18px;
}

.day-width {
  width: 75px !important;
  min-width: 75px !important;
  max-width: 75px !important;
}

/* Org chart */

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.title {
  background-color: #404e7c;
  color: #fef9ef;
  text-align: center;
  padding: 1rem;
  font-size: 1.5em;
  z-index: 1;
}

/* orgchart css */
.org-chart {
  height: calc(100vh - 60px);
}

.org-chart > svg {
  height: 100%;
  background-color: #eaeaea;
}

.node-container {
  min-height: 140px;
  background-color: #227c9d;
  color: #227c9d;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
}

.node-details {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.node-content {
  display: flex;
  align-items: center;
}

.node-team {
  width: 100%;
  text-align: center;
}

.node-team-name {
  margin-bottom: 0.5rem;
  color: #fef9ef;
  font-size: 1.5rem;
}

.node-team-member-img {
  width: 50px;
  height: 50px;
  margin: 0.2rem;
  border-radius: 50%;
}

.node-img {
  width: 90px;
  height: 90px;
  border-radius: 1rem;
}

.node-info {
  margin-left: 1.5rem;
  color: #fef9ef;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.node-name {
  padding-bottom: 0.3rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.node-role {
  padding-bottom: 0.5rem;
  font-size: 1.2rem;
}

.node-department {
  padding: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: #ffcb77;
  border-radius: 1rem;
  color: #227c9d;
}

.node-department > svg {
  margin-right: 0.5rem;
}

.expand-btn {
  width: 30px;
  height: 30px;
  margin: auto;
  color: #227c9d;
  background-color: #fef9ef;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #d3d3d3;
  border-radius: 50%;
  cursor: pointer;
}

.expand-btn > span:last-child {
  display: flex;
}

/* card css */
.card-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  color: #227c9d;
  background-color: #fef9ef;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #227c9d;
  cursor: pointer;
}

.card {
  position: absolute;
  top: 60px;
  left: 0;
  width: 25%;
  height: 75%;
  padding: 2rem;
  margin: 2rem;
  background-color: #fef9ef;
  border-radius: 1rem;
  border: 1px solid #d3d3d3;
  overflow: scroll;
}

.card-header {
  text-align: center;
  margin-bottom: 1rem;
}

.card-img {
  width: 120px;
  border-radius: 1rem;
}

.card-name {
  margin-top: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.card-role {
  margin: 1rem 0;
  font-size: 1.2rem;
}

.card-body {
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.card-body-team-members {
  margin-top: 1rem;
  height: 26vh;
  overflow: scroll;
}

.card-item {
  width: 100%;
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
}

.card-item-label {
  margin: 0.5rem 0;
  font-weight: bold;
}

.card-item-value {
  text-align: justify;
}

.card-item-team {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-item-img {
  width: 50px;
  height: 50px;
  margin: 0.2rem;
  border-radius: 50%;
}

.card-item-name {
  margin-left: 0.5rem;
  font-weight: bold;
}

.card-item-role {
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.false-color {
  color: red;
}

.true-color {
  color: green;
}

.public-terms-condition {
  border: 1px solid #227c9d;
  width: 92%;
  padding: 3px;
  height: 97px;
  overflow: auto;
  margin-top: -7px;
}

.cursor-pointer {
  cursor: pointer;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.mb-5 {
  margin-bottom: 5px;
}
.MuiDrawer-docked {
  flex: 0 0 auto;
  position: fixed;
  z-index: 999;
  background: #fafafa;
}

.noRecordText {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* border: 1px solid #00000008; */
  font-size: x-large;
  height: 500px;
}
.org-chart-border {
  border: 1px solid #25232317;
}
